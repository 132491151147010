import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useQuery } from 'react-query'
import { axiosPostCall, axiosGetCall } from '../../../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';

//mui
import { Box, Button, TableCell, TableContainer, Table, TableHead, TableRow, Popover, Paper, TableBody, styled, Tooltip, Chip, IconButton, Dialog, DialogTitle, DialogContent, Typography, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// config
import { USER_KYC_LIST_REFETCH_TIME } from '../../../config/commonConfig';

//logger
import Logger from '../../../lib/Logger';
import { PaginationTable } from '../announcement-ivr/paginationTable';
import { checkButtonAccess } from 'src/utils/common';
import UserFilters from './filters';
import moment from 'moment';
import CustomTabPanel from '../announcement-ivr/customTabPanel';
import Iconify from 'src/components/iconify';
const logger = new Logger("kycListTable");

const HeadingSection = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        flexWrap: "wrap",
    },
}));

export const TtsTemplateListTable = () => {

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState("")
    const [load, setLoad] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [selectedStatus, setSelectedStatus] = useState("P")
    const [confirmModal, setConfirmModal] = useState(false)

    const [filterLabels, setFilterLabels] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);

    const [selectedRow, setSelectedRow] = useState(null);
    const [filterData, setFilterData] = useState({
        userId: "",
        templateId: "",
        from: null,
        to: null,
    });

    const ttsTemplateColumns = [
        { id: 'user_id', label: 'User ID', minWidth: 100, align: 'center' },
        { id: 'template_id', label: 'Template Id', minWidth: 100, align: 'center' },
        { id: 'title', label: 'Title', minWidth: 110, align: 'center' },
        { id: 'template', label: 'Template', minWidth: 110, align: 'center' },
        { id: 'language', label: 'Language', minWidth: 110, align: 'center' },
        { id: 'dynamic_vars_count', label: 'Dynamic Vars', minWidth: 110, align: 'center' },
        { id: 'create_date', label: 'Date & Time', minWidth: 200, align: 'center' },
        { id: 'route', label: 'Route', minWidth: 110, align: 'center' },
        { id: 'actions', label: 'Action', minWidth: 110, align: 'center' },
    ];

    if (selectedStatus === "R") {
        ttsTemplateColumns.splice(6, 0, { id: 'rejectionReason', label: 'Rejection Reason', minWidth: 150, align: 'center' });
        ttsTemplateColumns.splice(7, 0, { id: 'action_by', label: 'Rejected BY', minWidth: 150, align: 'center' });
    }

    if (selectedStatus === "A") {
        ttsTemplateColumns.splice(6, 0, { id: 'action_by', label: 'Approved BY', minWidth: 150, align: 'center' });
    }

    const resetFilters = () => {
        setFilterLabels([]);
        setSearch("");
        setFilterData({
            userId: "",
            templateId: "",
            from: null,
            to: null,
        });
        setIsFilterApplied(false);
        setLoad(true);
    };

    const apply = (data) => {
        // setRows([]);
        setSearch("");
        setIsFilterApplied(true);

        const label = [];

        Object.keys(data).forEach((element) => {
            data[element] &&
                label.push({ key: filterLabelMap[element], value: data[element] });

        });
        setFilterLabels(label);

        const newData = JSON.parse(JSON.stringify(data));
        setFilterData(newData);
        // setPage(0);
        setLoad(true);
    };

    const generateLabelChipToolTip = (item) => {
        if (["From", "To"].includes(item?.key))
            return moment(item?.value).format("D MMM, YYYY");
        else return item?.value;
    };

    const filterLabelMap = {
        status: "Status",
        userId: "UserId",
        templateId: "TemplateId",
        from: "From",
        to: "To"
    };

    const reset = () => {
        resetFilters();
    };

    const findForSearchQuery = (search) => {
        setSearch(search);
        if (search?.trim()) {
            setPage(0);
            setLoad(true);
            if (!filterLabels.includes("search"))
                setFilterLabels([{ key: "search", value: search }]);
            setIsFilterApplied(false);
            return;
        }
        const updatedLabels = filterLabels.filter((item) => item?.key !== "search");
        setFilterLabels([...updatedLabels]);
        // setRows([]);
        setIsFilterApplied(false);
        // setPage(0);
        setLoad(true);
    };

    const handleChange = (value) => setSearch(value)

    const getTtsTemplateList = async () => {
        const skip = rowsPerPage * page;
        try {
            let params = { skip, limit: rowsPerPage }
            if (search) params.search = search
            if (selectedStatus) params.status = selectedStatus

            params = { ...params, ...filterData }

            setNetworkError(false)

            const data = await axiosGetCall("fetchTtsTemplate", params)
            setLoading(false);
            return data

        } catch (error) {
            logger.error("TtsTemplateListTable.jsx ,getTtsTemplateList ,getTtsTemplateList", error);
            setNetworkError(true)
            setRows([])
            setCount(0)
            renderErrorMessage(error?.message)
        }
        setLoading(false)
    }

    const { data, refetch } = useQuery({ queryKey: ['getTtsTemplateList', selectedStatus, page, rowsPerPage], queryFn: getTtsTemplateList, refetchOnWindowFocus: false, retry: false, refetchInterval: USER_KYC_LIST_REFETCH_TIME })
    useEffect(() => {

        setRows(data?.documents || [])
        setCount(data?.totalCount || 0)

    }, [data])

    useEffect(() => {
        if (load) {
            setLoading(true)
            refetch()
        }
        setLoad(false)
    }, [load])

    // const tableFilter = <TableFilters search={search} handleChange={handleChange} setLoad={setLoad} type={type} />

    const tableFilter = (
        <HeadingSection>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                }}
            >
                {/* <HeadingTypography>Customers</HeadingTypography> */}

                {!!filterLabels.length && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "6px",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        {filterLabels.map((item) => (
                            <Tooltip title={generateLabelChipToolTip(item)}>
                                <Chip label={item?.key} variant="outlined" size="small" />
                            </Tooltip>
                        ))}

                        <Button
                            variant="outlined"
                            size="small"
                            color="warning"
                            onClick={() => resetFilters()}
                        >
                            Clear Filter
                        </Button>
                    </Box>
                )}
            </Box>

            <UserFilters
                findForSearchQuery={findForSearchQuery}
                // getPlansList={refetch}
                apply={apply}
                reset={reset}
                search={search}
                isFilterApplied={isFilterApplied}
                filterData={filterData}
                setFilterData={setFilterData}
            />
        </HeadingSection >
    )

    const rejectStatus = (row) => {
        setSelectedRow(row)
        setConfirmModal(true);
    }

    const updateTtsTemplateStatus = async (row, status, type, rejectionReason) => {
        try {

            const { message } = await axiosPostCall("updateTemplateStatus", { type, templateId: row?.template_id, status, rejectionReason })
            renderSuccessMessage(message);
            setConfirmModal(false);
            refetch();

        } catch (error) {
            logger.error("ttsTemplateListTable.jsx ,updateTtsTemplateStatus ,updateTtsTemplateStatus", error);
            renderErrorMessage(error?.message)
        }
    }

    const ActionJsx = ({ id, align, value, row }) => {
        return (
            <TableCell
                key={id}
                align={align}
                sx={{
                    color: 'grey.400',
                    borderBottom: '0.5px dashed',
                    borderColor: 'grey.300',
                    size: 'small'
                }}>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px"
                    }}
                >

                    {checkButtonAccess("updateAnnouncementStatus", "w") && <>
                        {row?.status !== 1 && <IconButton onClick={() => updateTtsTemplateStatus(row, "A", "status")}><Iconify icon="mdi:tick" sx={{ color: "green" }} /></IconButton>}
                        {row?.status !== 2 && <IconButton onClick={() => rejectStatus(row, "R")}><Iconify icon="mdi:close" sx={{ color: "red" }} /></IconButton>}
                    </>}
                </Box>
            </TableCell>
        )
    }

    const contentStyle = { height: '20px', width: '20px', marginRight: '5px' }

    const Confirmation = () => {
        const [rejectionReason, setRejectionReason] = useState("");

        return (
            <Dialog open={confirmModal} PaperProps={{
                sx: {
                    width: "430px",
                    maxHeight: "400px"
                }
            }}>
                <DialogTitle >Update Template Status</DialogTitle>
                <DialogContent sx={{ marginTop: '-13px' }}>
                    <Typography>Are you sure you want to <span style={{ fontWeight: 'bold' }}>{'Reject '}</span>this Template.</Typography>
                    <TextField
                        required
                        id="outlined-required"
                        label="Reason"
                        defaultValue="Hello World"
                        multiline
                        maxRows={4}
                        fullWidth
                        value={rejectionReason}
                        onChange={(e) => setRejectionReason(e.target.value)}
                        sx={{ marginTop: "10px" }}
                    />
                </DialogContent>
                <DialogActions sx={{
                    marginTop: "-6px",
                    marginBottom: "5px",
                }}>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{
                            color: '#fff',
                            height: '30px',
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.main',
                            },
                            boxShadow: "none",
                            marginRight: '10px'
                        }}
                        onClick={() => updateTtsTemplateStatus(selectedRow, "R", "status", rejectionReason)}
                    >Confirm
                    </Button>
                    <Button
                        variant="outlined"
                        sx={{
                            height: '30px',
                            borderColor: 'red',
                            color: 'red',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.04)',
                                borderColor: 'red',
                            }
                        }}
                        onClick={() => setConfirmModal(false)}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog >
        );
    }


    const RouteJsx = ({ id, align, value, row }) => {
        const color = {
            transactional: "success.dark",
            promotional: "warning.dark",
            promotrans: "warning.dark"
        }

        const label = {
            transactional: "Transactional",
            promotional: "Promotional",
            promotrans: "Promo-Trans"
        }

        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            console.log("close")
            setAnchorEl(null);
            value !== route && updateRoute();
        };

        const open = Boolean(anchorEl);
        const popoverId = open ? 'simple-popover' : undefined;

        const [route, setRoute] = useState(value);

        const handleChange = (event) => {
            setRoute(event.target.value);
        };

        const updateRoute = () => {
            updateTtsTemplateStatus(row, route, "route")
        }


        return <TableCell
            key={id}
            align={align}
            sx={{
                color: 'grey.700',
                borderBottom: '0.5px dashed',
                borderColor: 'grey.300',
            }}>
            <>
                {value ?
                    selectedStatus === "P" ? <Tooltip title="Click here to Update Route">
                        <Chip label={label[value]}
                            sx={{
                                backgroundColor: 'transparent',
                                color: color[value],
                                '&:hover': {
                                    backgroundColor: 'transparent', color: color[value]
                                },
                                cursor: 'pointer'
                            }}
                            onClick={handleClick}
                            deleteIcon={<Iconify icon="material-symbols:edit" />}
                            onDelete={(e) => handleClick(e)}
                        />
                    </Tooltip> :
                        <Chip label={label[value]}
                            sx={{
                                backgroundColor: 'transparent',
                                color: color[value],
                                '&:hover': {
                                    backgroundColor: 'transparent', color: color[value]
                                },
                            }}
                        />
                    : "--"}
                {selectedStatus === "P" && <Popover
                    id={popoverId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <FormControl fullWidth sx={{ width: "150px" }} >
                        <InputLabel id="demo-simple-select-label">Route</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={route}
                            label="Route"
                            onChange={handleChange}
                        >
                            <MenuItem value={"transactional"}>Transactional</MenuItem>
                            <MenuItem value={"promotional"}>Promotional</MenuItem>
                        </Select>
                    </FormControl>
                </Popover>}
            </>
        </TableCell>
    }



    const TtsContentJsx = ({ id, align, value, row }) => {
        const [anchorEltts, setAnchorEltts] = React.useState(null);

        const handleClicktts = (event) => {
            setAnchorEltts(event.currentTarget);
        };

        const handleClosetts = () => {
            setAnchorEltts(null);
        };

        const opentts = Boolean(anchorEltts);
        const Id = opentts ? 'simple-popover' : undefined;

        return <TableCell
            key={id}
            align={align}
            sx={{
                color: 'grey.700',
                borderBottom: '0.5px dashed',
                borderColor: 'grey.300',
            }}>
            {Object.keys(value || {}).length > 0 ? <div>
                <Button aria-describedby={Id} variant="contained" onClick={handleClicktts} color='success'>
                    Content
                </Button>
                <Popover
                    id={Id}
                    open={opentts}
                    anchorEl={anchorEltts}
                    onClose={handleClosetts}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 200 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>

                                    <TableCell align="right">Node</TableCell>
                                    <TableCell align="right">Content</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(value).map((key) => (
                                    <TableRow
                                        key={key}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {key}
                                        </TableCell>
                                        <TableCell align="right">{value[key]}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Popover>
            </div> : "--"}
        </TableCell>
    }

    return (
        <>
            <ToastContainer />
            <Confirmation />
            <CustomTabPanel setSelectedStatus={setSelectedStatus} setLoading={setLoading} setPage={setPage} />
            <PaginationTable
                page={page}
                tableFilter={tableFilter}
                ActionJsx={ActionJsx}
                // ActionModals={ActionModals}
                setLoad={setLoad}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                error={networkError}
                loading={loading}
                setLoading={setLoading}
                rows={rows}
                columns={ttsTemplateColumns}
                handleChange={handleChange}
                search={search}
                calculatedHeight={377}
                TtsContentJsx={TtsContentJsx}
                RouteJsx={RouteJsx}
            />
        </>
    )
}