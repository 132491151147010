import React, { useEffect, useState } from 'react'
import { checkButtonAccess } from '../../utils/common';
import ErrorMessage from "../../utils/ErrorMessage.json";

//mui
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, Button, TextField, TableCell, TableContainer, TableHead, TableRow, Paper, Table, TableBody } from '@mui/material'

//mui icons
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import VerifiedIcon from '@mui/icons-material/Verified';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';

//components
import CustomModal from '../../components/modal/customModal';
import EmptyContent from '../../components/empty-content';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';
import NetworkError from '../../components/Error/networkError';

//apicall
import { axiosGetCall, axiosPostCall } from '../../services/apisCall';

//static Messages
import errorMessage from '../../utils/ErrorMessage.json';
import commonMessage from '../../utils/commonMessage.json';

//logger
import Logger from '../../lib/Logger';

const logger = new Logger("kycDetailsModal");

const buttonBaseStyle = {
    height: '24px',
    fontSize: '0.75rem',
    marginRight: '4px',
    padding: '4px 8px',
    '&:hover': {
        backgroundColor: 'transparent',
    }
};

const pendingButtonStyle = {
    ...buttonBaseStyle,
    color: '#50AB64',
    backgroundColor: 'rgba(40, 167, 69, 0.20)',
    border: '1px solid rgba(40, 167, 69, 0.20)',
    '&:hover': {
        backgroundColor: 'rgba(40, 167, 69, 0.20)',
        border: '1px solid #50AB64',
    },
};

const rejectButtonStyle = {
    ...buttonBaseStyle,
    color: '#E2533F',
    backgroundColor: 'rgba(236, 64, 64, 0.20)',
    border: '1px solid rgba(236, 64, 64, 0.20)',
    '&:hover': {
        backgroundColor: 'rgba(236, 64, 64, 0.20)',
        border: '1px solid #E2533F',
    },
};

const keyValueStyle = {
    display: 'flex'
};

let cellStyle = {
    color: 'grey.700',
    borderBottom: '0.5px dashed',
    borderColor: 'grey.300',
    fontWeight: 500,

}

const bodyCellStyle = {
    padding: '4px 8px', alignItems: 'center',
}

const typographyStyle = {
    fontSize: '0.75rem', lineHeight: 1.25, fontWeight: 400, color: 'grey.800'
}

const KycDetailsModal = ({ open, close, userId }) => {
    const [userDetail, setUserDetail] = useState([])
    const [documentsFile, setDocumentsFile] = useState([])
    const [showReject, setShowReject] = useState([])
    const [docUrl, setDocUrl] = useState("")
    const [rejectedMessageInputs, setRejectedMessageInputs] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [networkError, setNetworkError] = useState(false)

    const [confirmModal, setConfirmModal] = useState(false)
    const [updateStatusParams, setUpdateStatusParams] = useState([])

    const documentMapper = {
        pancard: "panNumber",
        aadhar: "aadharNumber",
        email: "email",
        gst: "gstNumber",
        authPanCard: "authPanNumber",
    }
    const getUserKycDoc = async () => {
        try {
            const { result: { userdetails, documentsFile } } = await axiosGetCall("getUserKycDoc", { userId })
            setUserDetail(userdetails)
            setDocumentsFile(documentsFile)

        } catch (error) {
            logger.error("Kycdetailsmodal.jsx ,getUserKycDoc ,getUserKycDoc", error);
            setNetworkError(true)
            renderErrorMessage(error?.message)
        }

    }
    const updateKycDocStatus = async () => {
        try {
            const [docName, kycStatusType, index] = updateStatusParams
            const params = { userId, docName, kycStatusType }
            const { message } = await axiosPostCall("updateKycDocStatus", params)
            handleStatusChange(index, kycStatusType)
            getUserKycDoc();
            updateStatusParams[1] === "rejected" && setShowReject([updateStatusParams[0], ...showReject])
            renderSuccessMessage(message)
        } catch (error) {
            logger.error("Kycdetailsmodal.jsx ,updateKycDocStatus ,updateKycDocStatus", error);
            renderErrorMessage(error?.message)

        }
        setConfirmModal(false)
    }

    const sendRejectedMail = async (docName, index) => {
        const rejectMessage = rejectedMessageInputs[index]
        try {
            const params = { userId, docName, rejectedMessage: rejectMessage }
            const { message } = await axiosPostCall("sendRejectedMail", params)
            getUserKycDoc()
            renderSuccessMessage(message)
            setShowReject(showReject?.filter((rejectDoc) => rejectDoc !== docName))

        } catch (error) {
            logger.error("KycDetailsModal.jsx ,sendRejectedMail ,sendRejectedMail", error);

            renderErrorMessage(error?.message)
        }
    }

    const handleChange = (e, index) => {
        const { value } = e.target
        const updatedInput = { ...rejectedMessageInputs }
        updatedInput[index] = value
        setRejectedMessageInputs(updatedInput)
    }

    const handleConfirm = (args) => {
        if (!checkButtonAccess("verifyKyc", "w")) return renderErrorMessage(ErrorMessage["invalidClick"])
        setUpdateStatusParams(args)
        setConfirmModal(true)
    }

    const handleConfirmClick = () => {
        updateKycDocStatus()
    }

    const handleSubmit = async () => {
        if (!checkButtonAccess("verifyKyc", "w")) return renderErrorMessage(ErrorMessage["invalidClick"])
        try {
            const { result: { userdetails } } = await axiosGetCall("getUserKycDoc", { userId });

            if (userdetails?.totalDocuments !== userdetails?.documentApprovedStatus) {
                close();
                return renderErrorMessage(errorMessage?.["documentsNotApproved"]);
            }
            if (userdetails?.kycStatus === "approved") {
                close();
                return renderErrorMessage(errorMessage?.["documentsAlreadyApproved"]);
            }

            const params = { userId, kycStatusType: "approved" }
            const { message } = await axiosPostCall("updateKycStatus", params)
            close()
            renderSuccessMessage(message)

        } catch (error) {
            logger.error("KycDetailsModal.jsx ,handleSubmit ,updateKycStatus", error);

            renderErrorMessage(error?.message)
        }

    }

    const showDocument = (fileUrl) => {
        setDocUrl(fileUrl)
        setOpenModal(true)
    }

    const handleStatusChange = (index, status) => {
        const updatedDocuments = [...documentsFile];
        updatedDocuments[index].status = status;
        setDocumentsFile(updatedDocuments);
    };

    useEffect(() => {
        if (open) getUserKycDoc()
    }, [open])

    const documentViewer = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: "85vh",
            gap: '20px',
            overflow: 'hidden',
            overflowY: "scroll",
        }}>
            <iframe
                src={docUrl}
                title="PDF Document"
                width="100%"
                height="100%"
            />
        </Box>
    )

    const ConfirmDialog = () => (
        <Dialog open={confirmModal} PaperProps={{
            sx: {
                width: "380px",
                maxHeight: "180px"
            }
        }}>
            <DialogTitle>Update</DialogTitle>
            <DialogContent sx={{
                marginTop: "-13px"
            }}>
                <Typography>Do you really want to update kyc Document status to <span style={{ fontWeight: 'bold' }}>{updateStatusParams[1]} </span>?</Typography>
            </DialogContent>
            <DialogActions sx={{
                marginTop: "-15px",
                marginBottom: "5px",

            }}>
                <Button color="secondary" variant="contained"
                    sx={{
                        color: '#fff',
                        height: '30px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                        },
                        boxShadow: "none"
                    }}
                    onClick={() => handleConfirmClick()}
                >
                    Update
                </Button>
                <Button
                    variant="outlined"
                    sx={{
                        height: '30px',
                        borderColor: 'red',
                        color: 'red',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                            borderColor: 'red',
                        }
                    }}
                    onClick={() => setConfirmModal(false)}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

    const description = (
        !networkError ? (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: "500px",
                gap: '20px',
                overflow: 'hidden',
                overflowY: "scroll",
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '16px',
                    marginLeft: '2px'
                }}>
                    <Box sx={keyValueStyle}>
                        <Typography sx={{
                            fontSize: '0.975rem',
                            fontWeight: 700,
                            color: '#637381',
                        }}>
                            {userDetail?.isCompanyKyc === "true" ? "Company Name :" : "Kyc Type :"}
                        </Typography>
                        <Typography sx={{
                            fontSize: '0.975rem',
                            fontWeight: 500,
                            color: 'grey.800',
                            marginLeft: '5px'
                        }}>
                            {userDetail?.isCompanyKyc === "true" ? userDetail?.companyName : userDetail?.userType}
                        </Typography>
                    </Box>

                    <Box sx={keyValueStyle}>
                        <Typography sx={{
                            fontSize: '0.975rem',
                            fontWeight: 700,
                            color: '#637381',
                        }}>
                            User Id :
                        </Typography>
                        <Typography sx={{
                            fontSize: '0.975rem',
                            lineHeight: 1.25,
                            fontWeight: 500,
                            color: 'grey.800',
                            marginLeft: '5px'

                        }}>
                            {userDetail?.userId}
                        </Typography>
                    </Box>

                    <Box sx={keyValueStyle}>
                        <Typography sx={{
                            fontSize: '0.975rem',

                            fontWeight: 700,
                            color: '#637381',
                        }}>
                            Email :
                        </Typography>
                        <Typography sx={{
                            fontSize: '0.975rem',
                            fontWeight: 500,
                            color: 'grey.800',
                            marginLeft: '5px'
                        }}>
                            {userDetail?.email}
                        </Typography>
                    </Box>
                    {userDetail?.isCompanyKyc === "true" && <>
                        <Box sx={keyValueStyle}>
                            <Typography sx={{
                                fontSize: '0.975rem',

                                fontWeight: 700,
                                color: '#637381',
                            }}>
                                Authorized Person Name :
                            </Typography>
                            <Typography sx={{
                                fontSize: '0.975rem',
                                fontWeight: 500,
                                color: 'grey.800',
                                marginLeft: '5px'
                            }}>
                                {userDetail?.authorizedPersonName}
                            </Typography>
                        </Box>
                        <Box sx={keyValueStyle}>
                            <Typography sx={{
                                fontSize: '0.975rem',

                                fontWeight: 700,
                                color: '#637381',
                            }}>
                                KYC Type :
                            </Typography>
                            <Typography sx={{
                                fontSize: '0.975rem',
                                fontWeight: 500,
                                color: 'grey.800',
                                marginLeft: '5px'
                            }}>
                                {userDetail?.userType}
                            </Typography>
                        </Box> </>}
                </Box>

                <TableContainer component={Paper} sx={{ overflow: 'auto', overflowY: "scroll", }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                        <TableHead x={{
                            position: "sticky"
                        }}>
                            <TableRow sx={{ lineHeight: 1.2, fontWeight: 500 }}>
                                <TableCell sx={{ ...cellStyle }}>Name</TableCell>
                                <TableCell sx={{ ...cellStyle }}>Number</TableCell>
                                <TableCell sx={{ ...cellStyle }}>File Name</TableCell>
                                <TableCell sx={{ ...cellStyle }}>Status</TableCell>
                                <TableCell sx={{ ...cellStyle }}>Reason</TableCell>

                                <TableCell sx={{ ...cellStyle }}>Actions</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                documentsFile.length > 0 ?
                                    (documentsFile?.map(({ name, file, fileName, rejectedMessage, status, totalRejectedTimes}, index) =>
                                    (
                                        <TableRow key={name}>
                                            <TableCell sx={{ ...bodyCellStyle }}>
                                                <Typography sx={{ fontSize: '0.75rem', lineHeight: 1.2, fontWeight: 500, color: '#637381' }}>
                                                    {[...name][0].toUpperCase() + [...name].slice(1).join('').replace(/([A-Z])/g, ' $1')}
                                                </Typography>

                                            </TableCell>
                                            <TableCell sx={{ ...bodyCellStyle }}>
                                                {name in documentMapper && (
                                                    <Typography sx={{ ...typographyStyle }}>
                                                        {userDetail[documentMapper[name]]}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ ...bodyCellStyle }}>
                                                <Typography sx={{ ...typographyStyle }}>
                                                    {fileName}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{ ...bodyCellStyle }}>
                                                <Typography sx={{ ...typographyStyle }}>
                                                    {status === "pending" && (
                                                        <Button
                                                            sx={{
                                                                ...buttonBaseStyle,
                                                                color: '#D19244',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            startIcon={<HistoryIcon />}
                                                            disableElevation
                                                            disableRipple
                                                            disableFocusRipple
                                                        >
                                                            Pending
                                                        </Button>
                                                    )}
                                                    {status === "approved" && (
                                                        <Button
                                                            sx={{
                                                                ...buttonBaseStyle,
                                                                color: '#50AB64',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            startIcon={<CheckIcon />}
                                                            disableElevation
                                                            disableRipple
                                                            disableFocusRipple
                                                        >
                                                            Approved
                                                        </Button>
                                                    )}
                                                    {status === "rejected" && (
                                                        <Button
                                                            sx={{
                                                                ...buttonBaseStyle,
                                                                color: '#E2533F',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                            startIcon={<CloseIcon />}
                                                            disableElevation
                                                            disableRipple
                                                            disableFocusRipple
                                                        >
                                                            Rejected ({totalRejectedTimes})
                                                        </Button>
                                                    )}
                                                </Typography>
                                            </TableCell>

                                            <TableCell sx={{ ...bodyCellStyle, minWidth: '200px', maxWidth: '200px' }}>
                                                <Typography sx={{ ...typographyStyle }}>
                                                    {rejectedMessage || "--"}
                                                </Typography>
                                            </TableCell>
                                            {showReject.includes(name) && (
                                                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                                    <TextField
                                                        type="text"
                                                        placeholder="Enter rejection reason here..."
                                                        inputProps={{ style: { height: '24px', fontSize: '0.75rem' } }}
                                                        size="small"
                                                        value={rejectedMessageInputs[index] || ""}
                                                        onChange={(e) => handleChange(e, index)}
                                                    />
                                                    <Button
                                                        sx={{
                                                            color: '#fff',
                                                            backgroundColor: 'primary.main',
                                                            height: '24px',
                                                            fontSize: '0.75rem',
                                                            padding: '4px 8px',
                                                            '&:hover': {
                                                                backgroundColor: 'primary.main',
                                                            }
                                                        }}
                                                        onClick={() => sendRejectedMail(name, index)}
                                                    >
                                                        Send mail
                                                    </Button>
                                                </Box>
                                            )}
                                            <TableCell sx={{ ...bodyCellStyle, display: 'flex', flexDirection: 'row' }}>
                                                {status === "pending" ? (
                                                    <>
                                                        <Button
                                                            sx={pendingButtonStyle}
                                                            onClick={(e) => showDocument(file)}
                                                            startIcon={<ImageSearchIcon sx={{ height: '15px', width: '15px' }} />}

                                                        >


                                                            Show
                                                        </Button>
                                                        {checkButtonAccess("verifyKyc", "r") && (
                                                            <Button
                                                                sx={pendingButtonStyle}
                                                                onClick={() => handleConfirm([name, "approved", index])}
                                                                startIcon={<VerifiedIcon sx={{ height: '15px', width: '15px' }} />}

                                                            >

                                                                Approve
                                                            </Button>
                                                        )}
                                                        {checkButtonAccess("verifyKyc", "r") && (
                                                            <Button
                                                                sx={rejectButtonStyle}
                                                                onClick={() => handleConfirm([name, "rejected", index])}
                                                                startIcon={<CancelIcon sx={{ height: '15px', width: '15px' }} />}
                                                            >

                                                                Reject
                                                            </Button>
                                                        )}
                                                    </>
                                                ) : <Button
                                                    sx={pendingButtonStyle}
                                                    onClick={(e) => showDocument(file)}
                                                    startIcon={<ImageSearchIcon sx={{ height: '15px', width: '15px' }} />}
                                                >

                                                    Show
                                                </Button>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                    )) : (<EmptyContent

                                        title="No Record Found!"
                                        sx={{
                                            py: 10,
                                            maxHeight: { md: 480 },
                                            alignItems:"center",
                                            justifyContent:"center"
                                        }}
                                    />)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomModal
                    open={openModal}
                    title={"Ducument File"}
                    description={documentViewer}
                    close={() => setOpenModal(false)}
                    width='900px'

                />
                <ConfirmDialog />

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '12px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: '100%',
                    pt: '20px',
                    pr: '30px'
                }}>

                </Box>

            </Box >) : (
            <Box sx={{
                height: {
                    xs: "480px",
                    sm: "480px",
                    md: "480px",
                    lg: "480px",
                    xl: "670px",
                },
                display: 'flex',
                justifyContent: 'center'
            }}>
                <NetworkError message={commonMessage?.netwo45253364rkError} />
            </Box>
        )
    )

    return (
        <CustomModal
            open={open}
            title={"Kyc Details"}
            description={description}
            close={close}
            width='1200px'
        />
    )
}

export default KycDetailsModal
