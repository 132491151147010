import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useQuery } from 'react-query'
import { axiosPostCall, axiosGetCall } from '../../services/apisCall';
import { renderErrorMessage, renderSuccessMessage } from '../../lib/toastMessage';

//mui
import { Box, Button, TableCell, List, ListItem, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ChecklistTwoToneIcon from '@mui/icons-material/ChecklistTwoTone';

// component
import CustomTabPanel from '../../components/TabPanel/customTabPanel';
import { PaginationTable } from '../../components/tables/paginationTable';
import { CustomMenu } from '../../components/menu/customMenu';


//sections
import TableFilters from './tableFilter';
import KycDetailsModal from './kycDetailsModal';

// config
import { USER_KYC_LIST_REFETCH_TIME } from '../../config/commonConfig';

//logger
import Logger from '../../lib/Logger';
const logger = new Logger("kycListTable");

const columns = [
    { id: 'userId', label: 'User ID', minWidth: 110, align: 'center' },
    { id: 'email', label: 'Email', minWidth: 110, align: 'center' },
    { id: 'userType', label: 'KYC Type', minWidth: 110, align: 'center' },
    { id: 'createdAt', label: 'Date & Time', minWidth: 110, align: 'center' },
    { id: 'kycBy', label: 'KYC By', minWidth: 110, align: 'center' },
    { id: 'documents', label: 'Document Approve Status', minWidth: 110, align: 'center' },
    { id: 'documentApprovedStatus', label: 'Approved Document', minWidth: 110, align: 'center' },
    { id: 'kycStatus', label: 'Status', minWidth: 110, align: 'center' },
    { id: 'nextStep', label: 'Upload Status', minWidth: 110, align: 'center' },
    { id: 'actions', label: 'Action', minWidth: 20, align: 'center' },
];


export const KycListTable = () => {

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rows, setRows] = useState([])
    const [count, setCount] = useState(0)
    const [pendingCount, setPendingCount] = useState(0)
    const [approvedCount, setApprovedCount] = useState(0)
    const [rejectedCount, setRejectedCount] = useState(0)
    const [partialVerifyCount, setPartialVerifyCount] = useState(0)
    const [search, setSearch] = useState("")
    const [load, setLoad] = useState(false)
    const [networkError, setNetworkError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [selectedStatus, setSelectedStatus] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedUserId, setSelectedUserId] = useState("")
    const [confirmModal, setConfirmModal] = useState(false)
    const [alreadyApproved, setAlreadyApproved] = useState(false)
    const [userKYCStatus, setUserKYCStatus] = useState("")

    const handleVerifyClick = (userId) => {
        setSelectedUserId(userId)
        setOpenModal(true)
        setAnchorEl(false)
    }
    const handleClick = (event, userId) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(userId)
    };
    const handleModalClose = () => {
        setOpenModal(false);
        setLoad(true)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (value) => setSearch(value)

    let partialVerify = async (userId) => {
        setAnchorEl(null);
        setConfirmModal(true);
        const { result: { userdetails } } = await axiosGetCall("getUserKycDoc", { userId });
        if (userdetails?.kycStatus === "approved" || userdetails?.kycStatus === "partialapproved") {
            setAlreadyApproved(true)
            setUserKYCStatus(userdetails?.kycStatus)
        }
        Confirmation();

    }

    let update = async (userId, flag) => {
        try {
            const kycStatusForUpdate = (flag !== undefined) ? "pending" : "partialapproved";
            setAlreadyApproved(false)
            setConfirmModal(false)
            const params = { userId, kycStatusType: kycStatusForUpdate }
            const { message } = await axiosPostCall("updateKycStatus", params)
            close()
            refetch()
            renderSuccessMessage(message)

        } catch (error) {
            renderErrorMessage(error.message)
        }
    }
    const updateCancel = () => {
        setConfirmModal(false)
        setAlreadyApproved(false)
    }
    const getKycUserList = async () => {
        const skip = rowsPerPage * page;
        try {
            const params = { skip, limit: rowsPerPage }
            if (search) params.userId = search
            if (selectedStatus) params.kycStatusType = selectedStatus
            setNetworkError(false)
            const data = await axiosGetCall("fetchKycUserList", params)
            setLoading(false)
            return data
        } catch (error) {
            logger.error("KycListTable.jsx ,getKycUserList ,fetchKycUserList", error);
            setNetworkError(true)
            setRows([])
            setCount(0)
            renderErrorMessage(error?.message)
        }
        setLoading(false)
    }

    const { data, refetch } = useQuery({ queryKey: ['getUserList', selectedStatus, page, rowsPerPage], queryFn: getKycUserList, refetchOnWindowFocus: false, retry: false, refetchInterval: USER_KYC_LIST_REFETCH_TIME })
    useEffect(() => {
        setRows(data?.result || [])
        setCount(data?.totalCount || 0)
        setPendingCount(data?.pendingCount || 0)
        setApprovedCount(data?.approvedCount || 0)
        setRejectedCount(data?.rejectedCount || 0)
        setPartialVerifyCount(data?.partialVerifyCount || 0)
    }, [data])

    useEffect(() => {
        if (load) {
            setLoading(true)
            refetch()
        }
        setLoad(false)
    }, [load])

    const tableFilter = <TableFilters search={search} handleChange={handleChange} setLoad={setLoad} />

    const ActionJsx = (id, align, value, userId, unused1, unused2, row) => {
        return (
            <TableCell
                key={id}
                align={align}
                sx={{
                    color: 'grey.400',
                    borderBottom: '0.5px dashed',
                    borderColor: 'grey.300',
                    size: 'small'
                }}>
                <MoreVertIcon
                    sx={{ color: 'grey.700', cursor: 'pointer', height: '18px', width: '18px' }}
                    aria-controls={open ? 'filters-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => handleClick(e, row?.userId)}
                />
            </TableCell>
        )
    }

    const contentStyle = { height: '20px', width: '20px', marginRight: '5px' }

    const Content = () => (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px'
        }}>
            <List>
                <ListItem sx={{
                    display: 'flex',
                    gap: '7px',
                    fontWeight: 400,
                    color: 'grey.700',
                    '&:hover': {
                        color: 'grey.800',
                        backgroundColor: 'grey.300',
                        cursor: "pointer"
                    }
                }}
                    onClick={() => handleVerifyClick(selectedUserId)}
                >
                    <VerifiedOutlinedIcon color='primary' sx={{ ...contentStyle }} />
                    Verify
                </ListItem>
                <ListItem sx={{
                    display: 'flex',
                    gap: '7px',
                    fontWeight: 400,
                    color: 'grey.700',
                    '&:hover': {
                        color: 'grey.800',
                        backgroundColor: 'grey.300',
                        cursor: "pointer"
                    }
                }}
                    onClick={() => partialVerify(selectedUserId)}
                >
                    <ChecklistTwoToneIcon color='secondary' sx={{ ...contentStyle }} />
                    Partial Verify
                </ListItem>
            </List>
        </Box>
    )

    const ActionModals = () => {
        return (
            <>
                {openModal ?
                    (<KycDetailsModal
                        open={openModal}
                        close={() => { handleModalClose() }}
                        Content={Content}
                        userId={selectedUserId}
                    />)
                    :
                    (<CustomMenu
                        anchorEl={anchorEl}
                        close={() => { handleModalClose() }}
                        Content={Content}
                        handleClose={handleClose}
                    />)
                }
            </>
        )
    }

    const Confirmation = () => (
        <Dialog open={confirmModal} PaperProps={{
            sx: {
                width: "370px",
                maxHeight: "180px"
            }
        }}>
            <DialogTitle >Partial Verify</DialogTitle>
            <DialogContent sx={{ marginTop: '-13px' }}>
                {alreadyApproved ? (<Typography> Already approved! would you like to <span style={{ fontWeight: 'bold' }}>{'Reset'}</span>?</Typography>)
                    : (<Typography>Are you sure you want to <span style={{ fontWeight: 'bold' }}>{'Partially update'}</span>?</Typography>)
                }          </DialogContent>
            <DialogActions sx={{
                marginTop: "-6px",
                marginBottom: "5px",
            }}>
                {alreadyApproved ? <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        color: '#fff',
                        height: '30px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                        },
                        boxShadow: "none",
                        marginRight: '10px'
                    }}
                    onClick={() => update(selectedUserId, { flag: 1 })}
                >
                    Reset
                </Button> : <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        color: '#fff',
                        height: '30px',
                        backgroundColor: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                        },
                        boxShadow: "none",
                        marginRight: '10px'
                    }}
                    onClick={() => update(selectedUserId)}
                >
                    Update
                </Button>}
                <Button
                    variant="outlined"
                    sx={{
                        height: '30px',
                        borderColor: 'red',
                        color: 'red',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                            borderColor: 'red',
                        }
                    }}
                    onClick={() => updateCancel()}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <ToastContainer />
            <Confirmation />
            <CustomTabPanel setSelectedStatus={setSelectedStatus} setLoading={setLoading} recordCount={[pendingCount, approvedCount, rejectedCount, partialVerifyCount]} setPage={setPage} />
            <PaginationTable
                page={page}
                tableFilter={tableFilter}
                ActionJsx={ActionJsx}
                ActionModals={ActionModals}
                setLoad={setLoad}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                count={count}
                error={networkError}
                loading={loading}
                setLoading={setLoading}
                rows={rows}
                columns={columns}
                handleChange={handleChange}
                search={search}
                calculatedHeight={377}
            />
        </>
    )
}