import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types'
import { Box, TableCell, Typography } from '@mui/material';
import Logger from '../../../lib/Logger';
import { renderErrorMessage, renderSuccessMessage } from '../../../lib/toastMessage';
import { LoadingButton } from '@mui/lab';

// import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

// import { CustomMenu } from 'src/components/menu/customMenu';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { axiosVBGetCall } from '../../../services/vbApiCall';
import { PaginationTable } from './paginationTable';
import { axiosPostCall } from 'src/services/apisCall';

const BasicDatePicker = ({ value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
                slotProps={{
                    textField: { size: 'small' }, actionBar: {
                        actions: ['today', 'clear'],
                    },
                }}
                sx={{ width: "270px", margin: "8px" }}
                value={value} onChange={(newValue) => onChange(newValue)}

            />
        </LocalizationProvider>
    )
}

BasicDatePicker.propTypes = {
    value: propTypes.number,
    onChange: propTypes.func.isRequired,
}

export const ResellerPlanTable = ({ credits = [], assignedPlan = [], user = {}, forceLoad, loading: tableLoading }) => {

    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const logger = new Logger("senderId");

    const handleAssignPlan = async (plan, setLoading) => {
        setLoading(true);

        try {

            const { plan_id: planId } = plan;
            const { voiceInfo: { userId } } = user;

            if (planId && userId) {
                const params = { assignTo: userId, planId }
                const { message } = await axiosPostCall("assignPlanToVoiceUser", params);
                forceLoad();
                renderSuccessMessage(message);

            }


        } catch (error) {
            logger.error("DeliveryLogsTable.jsx ,getDeliveryLogs ,deliveryLogs api", error);
            renderErrorMessage(error?.message)
        }
        setLoading(false);

    }


    const checkPlanAssignStatus = (planId) => {
        let count = false;
        assignedPlan.map(({ plan_id }) => {
            if (plan_id === planId) count = true;
            return null;
        })
        return count;
    }

    useEffect(() => {
        setRows(credits)
    }, [credits])

    const columns = [
        { id: 'plan_id', label: 'Plan ID', minWidth: 70, align: "center" },
        {
            id: 'allowCountry',
            label: 'Country Code',
            minWidth: 120,
            align: 'center',
        },
        { id: 'TotBal', label: 'Total Balance', minWidth: 125, align: "center" },
        { id: 'CurrBal', label: 'Current Balance', minWidth: 125, align: "center" },
        { id: 'Pulse', label: 'Pulse', minWidth: 125, align: "center" },

        {
            id: 'Validity',
            label: 'Validity',
            minWidth: 100,
            align: 'center',
        },

        {
            id: 'actions',
            label: 'Action',
            minWidth: 80,
            align: 'center',
        }

    ];

    const ActionJsx = ({ id, align, value, row }) => {
        const [assignPlanLoading, setAssignPlanLoading] = useState();
        return (
            <TableCell
                key={id}
                align={align}
                sx={{
                    color: 'grey.400',
                    borderBottom: '0.5px dashed',
                    borderColor: 'grey.700',
                }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                    <LoadingButton disabled={checkPlanAssignStatus(row?.plan_id)}
                        onClick={() => handleAssignPlan(row, setAssignPlanLoading)}
                        loading={assignPlanLoading}
                        variant="outlined" size='small' color='primary'>Assign</LoadingButton>

                </Box>
            </TableCell>
        )
    }

    return (
        <Box>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mb: "-20px" }}
            // sx={{ color: 'grey.10' }}
            >
                Reseller login Plans
            </Typography>
            <PaginationTable
                page={page}
                ActionJsx={ActionJsx}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                loading={tableLoading}
                rows={rows}
                columns={columns}
                maxHeight={680}
                mapKey="planName"
            />
        </Box>

    )

}

