import React, { useState, useEffect } from 'react';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, TextField, Divider, InputAdornment, Dialog } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from 'src/components/iconify';
import Tooltip from '@mui/material/Tooltip';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PreviewComponent from './preview';
import { useBoolean } from 'src/hooks/use-boolean';




const RowTow = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));



const BillingForm = () => {
    const theme = useTheme();
    const confirm = useBoolean();
    const [order, setOrder] = useState({
        fromAddress: {
            fullName: '',
            email: '',
            contactNumber: '',
            address: '',
            zipCode: '',
            city: '',
            state: '',
            country: ''
        },
        toAddress: {
            fullName: '',
            email: '',
            contactNumber: '',
            address: '',
            zipCode: '',
            city: '',
            state: '',
            country: ''
        },
        items: [{ product: '', description: '', quantity: '', rate: '', taxRate: '', taxAmount: '', discount: '', discountAmount: '', amount: 0 }],
        date: new Date(),
        totalSubTotal: 0, totalDiscount: 0, totalTax: 0, netPayable: 0
    });

    const validationSchema = Yup.object().shape({
        fromAddress: Yup.object().shape({
            fullName: Yup.string().trim().required('Full Name is required'),
            email: Yup.string().trim().email('Invalid email format').required('Email is required'),
            contactNumber: Yup.string()
                .trim()
                .matches(/^\d{10}$/, 'Contact Number must be a 10-digit number')
                .required('Contact Number is required'),
            address: Yup.string().trim().required('Address is required'),
            city: Yup.string().trim().required('City is required'),
            zipCode: Yup.string().trim().required('Zip Code is required'),
            state: Yup.string().trim().required('State is required'),
            country: Yup.string().trim(),
        }),
        toAddress: Yup.object().shape({
            fullName: Yup.string().trim().required('Full Name is required'),
            email: Yup.string().trim().email('Invalid email format').required('Email is required'),
            contactNumber: Yup.string()
                .trim()
                .matches(/^\d{10}$/, 'Contact Number must be a 10-digit number')
                .required('Contact Number is required'),
            address: Yup.string().trim().required('Address is required'),
            city: Yup.string().trim().required('City is required'),
            zipCode: Yup.string().trim().required('Zip Code is required'),
            state: Yup.string().trim().required('State is required'),
            country: Yup.string().trim(),
        })
    });

    // useEffect(() => {
    //     const newAmounts = order.items.map((item) => {
    //         const itemSubtotal = item.quantity * item.rate;
    //         const itemDiscount = itemSubtotal * (item.discount / 100);
    //         const itemGst = (itemSubtotal - itemDiscount) * (item.taxRate / 100);
    //         const netPayable = itemSubtotal - itemDiscount + itemGst;
    //         return { netPayable, amount: netPayable };
    //     });

    //     setCalculatedAmounts(newAmounts);

    //     const updatedItems = order.items.map((item, index) => ({
    //         ...item,
    //         amount: newAmounts[index]?.netPayable || 0,
    //     }));
    //     setorder({ ...order, items: updatedItems });
    // }, [order.items]);

    const inputSx = {
        color: theme.palette.text.primary,
        borderRadius: "6px",
    };

    const handleInputChange = (index, e) => {
        const newItems = [...order.items];
        newItems[index][e.target.name] = e.target.value;

        if (e.target.name === 'rate' || e.target.name === 'quantity' || e.target.name === 'taxRate' || e.target.name === 'discount') {
            const rate = parseFloat(newItems[index].rate) || 0;
            const quantity = parseFloat(newItems[index].quantity) || 0;
            const taxRate = parseFloat(newItems[index].taxRate) || 0;
            const discountRate = parseFloat(newItems[index].discount) || 0;

            const amount = rate * quantity;
            const taxAmount = (amount * taxRate) / 100;
            const discountAmount = (amount * discountRate) / 100;

            newItems[index].amount = amount - discountAmount + taxAmount;
            newItems[index].taxAmount = taxAmount;
            newItems[index].discountAmount = discountAmount;
        }

        setOrder({ ...order, items: newItems });
    };

    const handleRemoveItem = (index) => {
        const newItems = order.items.filter((_, i) => i !== index);
        setOrder({ ...order, items: newItems });
    };

    const calculateTotal = () => {
        const totals = order.items.reduce(
            (acc, item) => {
                const itemSubtotal = item.quantity * item.rate;

                const itemDiscount = itemSubtotal * (item.discount / 100);

                const validDiscount = Math.min(itemDiscount, itemSubtotal);

                const taxableAmount = itemSubtotal;

                const itemTax = taxableAmount * (item.taxRate / 100);

                acc.subtotal += parseFloat(itemSubtotal.toFixed(2));
                acc.discountAmount += parseFloat(validDiscount.toFixed(2));
                acc.taxAmount += parseFloat(itemTax.toFixed(2));

                return acc;
            },
            { subtotal: 0, discountAmount: 0, taxAmount: 0 }
        );
        // console.log("total;", totals)
        const netPayable = parseFloat((totals.subtotal - totals.discountAmount + totals.taxAmount).toFixed(2));
        setOrder((prevOrder) => ({
            ...prevOrder,
            totalSubTotal: totals.subtotal,
            totalDiscount: totals.discountAmount,
            totalTax: totals.taxAmount,
            netPayable,
        }));
    };

    useEffect(() => {
        calculateTotal();
    }, [order.items]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    };
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN').format(value);
    };
    return (
        <>
            <Box
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '25px',
                    width: '100%',
                    maxWidth: '1000px',
                    margin: 'auto',
                    padding: '25px',
                    boxShadow: 3,
                    borderRadius: '8px',
                }}
                noValidate
                autoComplete="off"
            >
                <Formik
                    initialValues={{
                        fromAddress: {
                            fullName: '', email: '', contactNumber: '', address: '',
                            city: '', zipCode: '', state: '', country: ''
                        },
                        toAddress: {
                            fullName: '', email: '', contactNumber: '', address: '',
                            city: '', zipCode: '', state: '', country: ''
                        }
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log('Form data', values);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form style={{ width: '100%', gap: 1 }}>
                            <Box sx={{ width: '100%', gap: 1, padding: '0 5px' }}>
                                <h4 style={{ marginTop: '20px ' }}>From</h4>
                                <RowTow sx={{ display: 'flex', width: '100%' }}>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Full Name *"
                                        name="fromAddress.fullName"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.fullName && errors.fromAddress?.fullName}
                                        error={touched.fromAddress?.fullName && Boolean(errors.fromAddress?.fullName)}
                                        sx={{ marginBottom: 1 }}
                                        onFocus={(e) => e.target.select()}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Email *"
                                        name="fromAddress.email"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.email && errors.fromAddress?.email}
                                        error={touched.fromAddress?.email && Boolean(errors.fromAddress?.email)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Contact Number *"
                                        name="toAddress.contactNumber"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.contactNumber && errors.toAddress?.contactNumber}
                                        error={touched.toAddress?.contactNumber && Boolean(errors.toAddress?.contactNumber)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Address *"
                                        name="toAddress.address"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.address && errors.toAddress?.address}
                                        error={touched.toAddress?.address && Boolean(errors.toAddress?.address)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="City *"
                                        name="fromAddress.city"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.city && errors.fromAddress?.city}
                                        error={touched.fromAddress?.city && Boolean(errors.fromAddress?.city)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Zip/Code *"
                                        name="fromAddress.zipCode"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.zipCode && errors.fromAddress?.zipCode}
                                        error={touched.fromAddress?.zipCode && Boolean(errors.fromAddress?.zipCode)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="State *"
                                        name="fromAddress.state"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.fromAddress?.state && errors.fromAddress?.state}
                                        error={touched.fromAddress?.state && Boolean(errors.fromAddress?.state)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Country"
                                        name="fromAddress.country"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                            </Box>

                            <Box sx={{ width: '100%', gap: 1, padding: '0 5px' }}>
                                <h4 style={{ marginTop: '20px' }}>To</h4>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Full Name *"
                                        name="toAddress.fullName"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.fullName && errors.toAddress?.fullName}
                                        error={touched.toAddress?.fullName && Boolean(errors.toAddress?.fullName)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Email *"
                                        name="toAddress.email"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.email && errors.toAddress?.email}
                                        error={touched.toAddress?.email && Boolean(errors.toAddress?.email)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                                <RowTow>
                                    <TextField
                                        fullWidth
                                        label="Contact Number *"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        value={order.toAddress.contactNumber}
                                        onChange={(e) => setOrder({ ...order, toAddress: { ...order.toAddress, contactNumber: e.target.value } })}
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Address *"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        value={order.toAddress.address}
                                        onChange={(e) => setOrder({ ...order, toAddress: { ...order.toAddress, address: e.target.value } })}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="City *"
                                        name="toAddress.city"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.city && errors.toAddress?.city}
                                        error={touched.toAddress?.city && Boolean(errors.toAddress?.city)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Zip Code *"
                                        name="toAddress.zipCode"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.zipCode && errors.toAddress?.zipCode}
                                        error={touched.toAddress?.zipCode && Boolean(errors.toAddress?.zipCode)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                                <RowTow>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="State *"
                                        name="toAddress.state"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        helperText={touched.toAddress?.state && errors.toAddress?.state}
                                        error={touched.toAddress?.state && Boolean(errors.toAddress?.state)}
                                        sx={{ marginBottom: 1 }}
                                    />
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Country"
                                        name="toAddress.country"
                                        variant="outlined"
                                        InputProps={{ sx: inputSx }}
                                        sx={{ marginBottom: 1 }}
                                    />
                                </RowTow>
                            </Box>
                        </Form>
                    )}
                </Formik>
                <div>
                    <h4>Items</h4>

                    {order.items.map((item, index) => (
                        <div key={index} style={{ marginBottom: '15px', position: 'relative' }}>
                            <RowTow>
                                <TextField
                                    fullWidth
                                    label="Product"
                                    variant="outlined"
                                    InputProps={{ sx: inputSx }}
                                    name="product"
                                    value={item.product}
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                                <TextField
                                    fullWidth
                                    label="Description"
                                    variant="outlined"
                                    InputProps={{ sx: inputSx }}
                                    name="description"
                                    value={item.description}
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                                <TextField
                                    fullWidth
                                    label="Quantity"
                                    variant="outlined"
                                    InputProps={{ sx: inputSx }}
                                    type="number"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                                <TextField
                                    fullWidth
                                    label="Price"
                                    variant="outlined"
                                    InputProps={{ sx: inputSx }}
                                    type="number"
                                    name="rate"
                                    value={item.rate}
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                                <TextField
                                    fullWidth
                                    label="Tax (%)"
                                    variant="outlined"
                                    InputProps={{ sx: inputSx }}
                                    type="number"
                                    name="taxRate"
                                    value={item.taxRate}
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                                <TextField
                                    fullWidth
                                    label="Disc (%)"
                                    variant="outlined"
                                    InputProps={{ sx: inputSx }}
                                    type="number"
                                    name="discount"
                                    value={item.discount}
                                    onChange={(e) => handleInputChange(index, e)}
                                />
                                <TextField
                                    key={index}
                                    fullWidth
                                    label="Amount(₹)"
                                    variant="outlined"
                                    InputProps={{
                                        sx: inputSx
                                        // startAdornment: (
                                        //     <InputAdornment position="start">
                                        //         <CurrencyRupeeRoundedIcon sx={{ fontSize: '1rem' }} />
                                        //     </InputAdornment>
                                        // ),
                                    }}
                                    type="number"
                                    name="amount"
                                    value={order.items[index]?.amount?.toFixed(2) || 0}
                                    onChange={(e) => {
                                        const updatedItems = [...order.items];
                                        const newAmount = parseFloat(e.target.value) || 0;
                                        updatedItems[index] = {
                                            ...updatedItems[index],
                                            amount: newAmount,
                                        };
                                        setOrder({ ...order, items: updatedItems });
                                    }}
                                />
                                <Tooltip title="Delete Item">
                                    <IconButton
                                        onClick={() => handleRemoveItem(index)}
                                        sx={{ ml: 0.5 }}
                                    >
                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                    </IconButton>
                                </Tooltip>
                            </RowTow>

                        </div>
                    ))}
                    <Tooltip title="Add Item">
                        <IconButton
                            onClick={() =>
                                setOrder({
                                    ...order,
                                    items: [...order.items, { product: '', description: '', quantity: '', rate: '', taxRate: '', taxAmount: 0, discount: '', discountAmount: 0, amount: 0 }]
                                })
                            }
                            sx={{
                                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
                                border: (theme) => `dashed 1px ${theme.palette.divider}`,
                            }}
                        >
                            <Iconify icon="mingcute:add-line" />
                        </IconButton>
                    </Tooltip>
                </div>

                <div style={{ marginTop: '8px', width: '100%' }}>
                    <Box sx={{ marginTop: '8px', width: '100%' }}>
                        {[
                            { label: 'Subtotal(₹):', value: order.totalSubTotal.toFixed(2), isBold: true },
                            { label: 'Discount(₹):', value: order.totalDiscount.toFixed(2) },
                            { label: 'Tax(₹):', value: order.totalTax.toFixed(2) }
                        ].map((item, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <div style={{ textAlign: 'right', width: '80%' }}>
                                    <span style={{ fontWeight: item.isBold ? 'bold' : 'normal' }}>{item.label}</span>
                                </div>
                                <div style={{ textAlign: 'right', width: '20%' }}>
                                    <span>{item.value}</span>
                                </div>
                                <Divider sx={{ my: 0.5, borderBottomWidth: 1, flex: 1 }} />
                            </div>
                        ))}

                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center' }}>
                            <div style={{ flexGrow: 1 }} />
                            <Divider sx={{ my: 0.5, borderBottomWidth: 1, width: '28%', marginLeft: 'auto' }} />
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <div style={{ textAlign: 'right', width: '80%' }}>
                                <span style={{ fontWeight: 'bold' }}>Total:</span>
                            </div>
                            <div style={{ textAlign: 'right', width: '20%' }}>
                                <span>₹ {formatCurrency(order.netPayable.toFixed(2))}</span>
                            </div>
                        </div>
                    </Box>
                </div>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pt: '10px',
                    pb: '20px',
                    gap: "15px"
                }}>
                    <LoadingButton
                        variant='outlined'
                        onClick={() => confirm.onTrue()}
                    >
                        Preview
                    </LoadingButton>
                    <LoadingButton
                        variant='outlined'
                        onSubmit={handleSubmit}
                    >
                        Submit
                    </LoadingButton>
                </Box>

            </Box>

            <PreviewComponent
                open={confirm.value}
                setOpen={confirm.onFalse}
                order={order}
            />
        </>
    );
};

export default BillingForm;
