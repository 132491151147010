import React, { useEffect, useState } from 'react';
import { Menu, styled, TextField, Typography, useTheme, Box, Button } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const RowBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
    width: '100%'
}))

const RowInnerBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    width: 'calc(100%/2 - 5px)',
    gap: '5px'
}))

const Key = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[600],
}))

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'auto',
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const FilterMenu = ({ anchorEl, open, handleClose, reset, apply, isFilterApplied }) => {
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [userId, setUserId] = useState('');
    const [templateId, setTemplateId] = useState('')

    useEffect(() => {
        if (!isFilterApplied) {

            setFrom(null);
            setTo(null);
            setUserId('');
            setTemplateId('');
        }

    }, [isFilterApplied])

    const resetFilter = () => {

        setFrom(null);
        setTo(null);
        setUserId('');
        setTemplateId('');
        reset()
    }

    const applyFilter = () => {
        let data = {
            // email,

            // mobile,
            userId,
            templateId,
            // status,
            // type,
            // permissions,
            // downlineType,
            // status,
            // planId,
            // productId
        }

        if (from) data.from = +new Date(from);
        if (to) data.to = +new Date(to);

        apply(data)
    }

    return (
        <Menu
            id="filter-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'filter-button',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                gap: '15px'
            }}>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <RowBox>
                        <RowInnerBox>
                            <Key>
                                From
                            </Key>

                            <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                value={from}
                                onChange={(e) => setFrom(e)}
                            />
                            {/* <TextField
                                size='small'
                                placeholder='Enter Product Id'
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                            /> */}
                        </RowInnerBox>

                        <RowInnerBox>
                            <Key>
                                To
                            </Key>

                            <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                value={to}
                                onChange={(e) => setTo(e)} />
                            {/* <TextField
                                size='small'
                                placeholder='Enter Plan Id'
                                value={planId}
                                onChange={(e) => setPlanId(e.target.value)}
                            /> */}
                        </RowInnerBox>

                    </RowBox>
                </LocalizationProvider>

                <RowBox>
                    <RowInnerBox>
                        <Key>
                            User ID
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter User Id'
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                    </RowInnerBox>

                    <RowInnerBox>
                        <Key>
                            Template ID
                        </Key>

                        <TextField
                            size='small'
                            placeholder="Enter Template Id"
                            value={templateId}
                            onChange={(e) => setTemplateId(e.target.value)}
                        />
                    </RowInnerBox>

                </RowBox>

                {/* <RowBox>
                    <RowInnerBox>
                        <Key>
                            User ID
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter user ID'
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                    </RowInnerBox>

                    <RowInnerBox>
                        <Key>
                            Mobile Number
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter mobile number'
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />
                    </RowInnerBox>

                </RowBox> */}

                {/* <RowBox>
                    <RowInnerBox>
                        <Key>
                            Email
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </RowInnerBox>

                    <RowInnerBox>
                        <Key>
                            Status
                        </Key>

                        <FormControl>
                            <Select
                                id="demo-multiple-chip"
                                multiple
                                size='small'
                                value={status}
                                onChange={handleChangeStatus}
                                input={<OutlinedInput id="select-multiple-chip" placeholder='Select status' />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value.charAt(0).toUpperCase()
                                                + value.slice(1)} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {["active", "suspend"].map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, status, theme)}
                                    >
                                        {name.charAt(0).toUpperCase()
                                            + name.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </RowInnerBox>

                </RowBox> */}

                {/* <RowBox>
                    <RowInnerBox>
                        <Key>
                            Downline Type
                        </Key>
                        <FormControl>
                            <Select
                                id="demo-multiple-chip"
                                size='small'
                                value={downlineType}
                                onChange={handleChangeDownlineType}
                            >
                                <MenuItem
                                    value=""
                                >
                                    All
                                </MenuItem>
                                <MenuItem
                                    value="downline"
                                >
                                    Downline
                                </MenuItem>
                                <MenuItem
                                    value="hierarchy"
                                >
                                    Hierarchy
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </RowInnerBox>

                </RowBox> */}

                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '8px',
                    mt: '10px'
                }}>

                    <Button
                        variant='contained'
                        sx={{ boxShadow: "none" }}
                        onClick={() => applyFilter()}
                    >
                        Apply
                    </Button>

                    <Button
                        variant='outlined'
                        color='warning'
                        sx={{ boxShadow: "none" }}
                        onClick={() => resetFilter()}
                    >
                        Reset Filters
                    </Button>

                    <Button
                        variant='outlined'
                        sx={{ boxShadow: "none" }}
                        onClick={() => handleClose()}
                    >
                        Close
                    </Button>

                </Box>

            </Box>
        </Menu>
    )
}

export default FilterMenu