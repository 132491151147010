import React, { useEffect, useState } from 'react';
import { Menu, styled, TextField, Typography, useTheme, Box, Button } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const RowBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
    width: '100%'
}))

const RowInnerBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    width: 'calc(100%/2 - 5px)',
    gap: '5px'
}))

const Key = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[600],
}))

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'auto',
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const FilterMenu = ({ anchorEl, open, handleClose, reset, apply, isFilterApplied, getPlansList, }) => {
    const theme = useTheme();
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [userId, setUserId] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [downlineType, setDownlineType] = useState("");

    const [status, setStatus] = useState("active");
    const [planId, setPlanId] = useState("");
    const [productId, setProductId] = useState("");



    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeType = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            value
        );
    };

    const handleChangeDownlineType = (event) => {
        setDownlineType(event.target.value)
    }

    const handleChangePermissions = (event) => {
        const {
            target: { value },
        } = event;
        setPermissions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        if (!isFilterApplied) {
            setStatus("active");
            setProductId("");
            setPlanId("");
        }

    }, [isFilterApplied])

    const resetFilter = () => {

        setStatus("active");
        setProductId("");
        setPlanId("");
        reset()
    }

    const applyFilter = () => {
        let data = {
            // email,

            // mobile,
            // userId,
            // status,
            // type,
            // permissions,
            // downlineType,
            status,
            planId,
            productId
        }

        // if (from) data.from = +new Date(from);
        // if (to) data.to = +new Date(to);

        apply(data)
    }

    return (
        <Menu
            id="filter-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'filter-button',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                gap: '15px'
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <RowBox>
                        <RowInnerBox>
                            <Key>
                                Product ID
                            </Key>

                            {/* <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                value={from}
                                onChange={(e) => setFrom(e)}
                            /> */}
                            <TextField
                                size='small'
                                placeholder='Enter Product Id'
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                            />
                        </RowInnerBox>

                        <RowInnerBox>
                            <Key>
                                Plan ID
                            </Key>

                            {/* <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                value={to}
                                onChange={(e) => setTo(e)} /> */}
                            <TextField
                                size='small'
                                placeholder='Enter Plan Id'
                                value={planId}
                                onChange={(e) => setPlanId(e.target.value)}
                            />
                        </RowInnerBox>

                    </RowBox>
                </LocalizationProvider>

                {/* <RowBox>
                    <RowInnerBox>
                        <Key>
                            User ID
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter user ID'
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                    </RowInnerBox>

                    <RowInnerBox>
                        <Key>
                            Mobile Number
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter mobile number'
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                        />
                    </RowInnerBox>

                </RowBox> */}

                {/* <RowBox>
                    <RowInnerBox>
                        <Key>
                            Email
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </RowInnerBox>

                    <RowInnerBox>
                        <Key>
                            Status
                        </Key>

                        <FormControl>
                            <Select
                                id="demo-multiple-chip"
                                multiple
                                size='small'
                                value={status}
                                onChange={handleChangeStatus}
                                input={<OutlinedInput id="select-multiple-chip" placeholder='Select status' />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value.charAt(0).toUpperCase()
                                                + value.slice(1)} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {["active", "suspend"].map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, status, theme)}
                                    >
                                        {name.charAt(0).toUpperCase()
                                            + name.slice(1)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </RowInnerBox>

                </RowBox> */}

                <RowBox>
                    <RowInnerBox>
                        <Key>
                            Plan Status
                        </Key>

                        <FormControl>
                            <Select
                                id="demo-multiple-chip"
                                size='small'
                                value={status}
                                onChange={handleChangeType}
                            // input={<OutlinedInput id="select-multiple-chip" placeholder='Select status' />}
                            // renderValue={(selected) => (
                            //     <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            //         {selected.map((value) => (
                            //             <Chip key={value} label={value} />
                            //         ))}
                            //     </Box>
                            // )}
                            // MenuProps={MenuProps}
                            >
                                <MenuItem
                                    value="active"
                                >
                                    Active
                                </MenuItem>


                                <MenuItem
                                    value="inactive"
                                >
                                    Inactive
                                </MenuItem>
                                <MenuItem
                                    value="all"
                                >
                                    ALL
                                </MenuItem>

                                {/* {['Self', 'Manual'].map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, type, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))} */}
                            </Select>
                        </FormControl>
                    </RowInnerBox>

                    {/* <RowInnerBox>
                        <Key>
                            Permissions
                        </Key>

                        <FormControl>
                            <Select
                                id="demo-multiple-chip"
                                multiple
                                size='small'
                                value={permissions}
                                onChange={handleChangePermissions}
                                input={<OutlinedInput id="select-multiple-chip" placeholder='Select status' />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {['L1', 'L4'].map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        style={getStyles(name, permissions, theme)}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </RowInnerBox> */}

                </RowBox>

                {/* <RowBox>
                    <RowInnerBox>
                        <Key>
                            Downline Type
                        </Key>
                        <FormControl>
                            <Select
                                id="demo-multiple-chip"
                                size='small'
                                value={downlineType}
                                onChange={handleChangeDownlineType}
                            >
                                <MenuItem
                                    value=""
                                >
                                    All
                                </MenuItem>
                                <MenuItem
                                    value="downline"
                                >
                                    Downline
                                </MenuItem>
                                <MenuItem
                                    value="hierarchy"
                                >
                                    Hierarchy
                                </MenuItem>

                            </Select>
                        </FormControl>
                    </RowInnerBox>

                </RowBox> */}

                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '8px',
                    mt: '10px'
                }}>

                    <Button
                        variant='contained'
                        sx={{ boxShadow: "none" }}
                        onClick={() => applyFilter()}
                    >
                        Apply
                    </Button>

                    <Button
                        variant='outlined'
                        color='warning'
                        sx={{ boxShadow: "none" }}
                        onClick={() => resetFilter()}
                    >
                        Reset Filters
                    </Button>

                    <Button
                        variant='outlined'
                        sx={{ boxShadow: "none" }}
                        onClick={() => handleClose()}
                    >
                        Close
                    </Button>

                </Box>

            </Box>
        </Menu>
    )
}

export default FilterMenu