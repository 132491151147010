import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomTabPanel = ({ setSelectedStatus, setLoading, setPage }) => {
    const [value, setValue] = React.useState(0);
    const statusMapper = {
        0: "P",
        1: "A",
        2: "R"
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setLoading(true);
        setPage(0);
        setSelectedStatus(statusMapper[newValue])
    };

    // const allLabel = (
    //     <Box sx={{
    //         display: 'flex',
    //         justifyContent: 'center',
    //         alignItems: 'center',
    //         gap: '10px', statusMapper,
    //         flexDirection: 'row',
    //         ml: '15px',
    //         mr: '10px'
    //     }}>
    //         <Typography sx={{
    //             fontSize: '0.875rem',
    //             lineHeight: 1.25,
    //             fontWeight: 700,
    //             color: value === 0 ? '#2065D1' : '#637381',
    //         }}>
    //             All
    //         </Typography>
    //         <Typography sx={{
    //             fontSize: '0.875rem',
    //             lineHeight: 1.25,
    //             fontWeight: 700,
    //             backgroundColor: 'rgb(33, 43, 54)',
    //             color: 'grey.100',
    //             p: '3px 6px 3px 6px',
    //             borderRadius: '6px'
    //         }}>
    //             {pendingCount + approvedCount + rejectedCount + partialVerifyCount}
    //         </Typography>
    //     </Box>
    // )

    const pendingLabel = (
        <Box sx={{
            isplay: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row',
            ml: '15px',
            mr: '10px'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 0 ? '#2065D1' : '#637381',
            }}>
                Pending
            </Typography>
            {/* <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 1 ? "grey.100" : '#D19244',
                backgroundColor: value === 1 ? "#D19244" : 'rgba(209, 146, 68, 0.20)',
                p: '3px 6px 3px 6px',
                borderRadius: '6px'
            }}>
                {pendingCount}
            </Typography> */}
        </Box>
    )

    const approvedLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 1 ? '#2065D1' : '#637381',
            }}>
                Approved
            </Typography>
            {/* <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 2 ? "grey.100" : '#73BB82',
                backgroundColor: value === 2 ? "#73BB82" : 'rgba(115, 187, 130, 0.20)',
                p: '3px 6px 3px 6px',
                borderRadius: '6px'
            }}>
                {approvedCount}
            </Typography> */}
        </Box>
    )

    const rejectedLabel = (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            flexDirection: 'row'
        }}>
            <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 2 ? '#2065D1' : '#637381',
            }}>
                Rejected
            </Typography>
            {/* <Typography sx={{
                fontSize: '0.875rem',
                lineHeight: 1.25,
                fontWeight: 700,
                color: value === 3 ? "grey.100" : '#E2533F',
                backgroundColor: value === 3 ? "#E2533F" : 'rgba(236, 64, 64, 0.20)',
                p: '3px 6px 3px 6px',
                borderRadius: '6px'
            }}>
                {rejectedCount}
            </Typography> */}
        </Box>
    )

    return (
        <Box sx={{
            width: '100%',
            backgroundColor: '#fff'
        }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {/* <Tab label={allLabel} {...a11yProps(0)} /> */}
                    <Tab label={pendingLabel} {...a11yProps(0)} />
                    <Tab label={approvedLabel} {...a11yProps(1)} />
                    <Tab label={rejectedLabel} {...a11yProps(2)} />
                    {/* <Tab label={partialVerifyLabel} {...a11yProps(2)} /> */}
                </Tabs>
            </Box>
            {/* <CustomTabPanelStyle value={value} index={0}>
                Item One
            </CustomTabPanelStyle>
            <CustomTabPanelStyle value={value} index={1}>
                Item Two
            </CustomTabPanelStyle>
            <CustomTabPanelStyle value={value} index={2}>
                Item Three
            </CustomTabPanelStyle> */}
        </Box>
    )
}
export default CustomTabPanel;
