import React, { useEffect, useState } from 'react';
import { Menu, styled, TextField, Typography, useTheme, Box, Button } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const RowBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '10px',
    width: '100%'
}))

const RowInnerBox = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    width: 'calc(100%/2 - 5px)',
    gap: '5px'
}))

const Key = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[600],
}))

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'auto',
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const FilterMenu = ({ anchorEl, open, handleClose, reset, apply, isFilterApplied, type }) => {
    const theme = useTheme();
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [userId, setUserId] = useState('');
    const [annId, setAnnId] = useState('')
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [permissions, setPermissions] = useState([]);
    const [downlineType, setDownlineType] = useState("");

    const [status, setStatus] = useState("active");
    const [planId, setPlanId] = useState("");
    const [productId, setProductId] = useState("");

    const handleChangeStatus = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChangeType = (event) => {
        const {
            target: { value },
        } = event;
        setStatus(
            value
        );
    };

    const handleChangeDownlineType = (event) => {
        setDownlineType(event.target.value)
    }

    const handleChangePermissions = (event) => {
        const {
            target: { value },
        } = event;
        setPermissions(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        if (!isFilterApplied) {
            setFrom(null);
            setTo(null);
            setUserId('');
            setAnnId('');
        }

    }, [isFilterApplied])

    const resetFilter = () => {

        setFrom(null);
        setTo(null);
        setUserId('');
        setAnnId('');
        reset()
    }

    const applyFilter = () => {
        let data = {
            // email,

            // mobile,
            userId,
            annId,
            // status,
            // type,
            // permissions,
            // downlineType,
            // status,
            // planId,
            // productId
        }

        if (from) data.from = +new Date(from);
        if (to) data.to = +new Date(to);

        apply(data)
    }

    return (
        <Menu
            id="filter-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'filter-button',
            }}
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                gap: '15px'
            }}>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <RowBox>
                        <RowInnerBox>
                            <Key>
                                From
                            </Key>

                            <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                value={from}
                                onChange={(e) => setFrom(e)}
                            />
                            {/* <TextField
                                size='small'
                                placeholder='Enter Product Id'
                                value={productId}
                                onChange={(e) => setProductId(e.target.value)}
                            /> */}
                        </RowInnerBox>

                        <RowInnerBox>
                            <Key>
                                To
                            </Key>

                            <DatePicker
                                slotProps={{ textField: { size: 'small' } }}
                                value={to}
                                onChange={(e) => setTo(e)} />
                            {/* <TextField
                                size='small'
                                placeholder='Enter Plan Id'
                                value={planId}
                                onChange={(e) => setPlanId(e.target.value)}
                            /> */}
                        </RowInnerBox>

                    </RowBox>
                </LocalizationProvider>

                <RowBox>
                    <RowInnerBox>
                        <Key>
                            User ID
                        </Key>

                        <TextField
                            size='small'
                            placeholder='Enter User Id'
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                    </RowInnerBox>

                    <RowInnerBox>
                        <Key>
                            {type === "announcement" ? "Announcement ID" : "Ivr ID"}
                        </Key>

                        <TextField
                            size='small'
                            placeholder={type === "announcement" ? 'Enter Announcement Id' : 'Enter IVR Id'}
                            value={annId}
                            onChange={(e) => setAnnId(e.target.value)}
                        />
                    </RowInnerBox>

                </RowBox>

                <Box sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                    gap: '8px',
                    mt: '10px'
                }}>

                    <Button
                        variant='contained'
                        sx={{ boxShadow: "none" }}
                        onClick={() => applyFilter()}
                    >
                        Apply
                    </Button>

                    <Button
                        variant='outlined'
                        color='warning'
                        sx={{ boxShadow: "none" }}
                        onClick={() => resetFilter()}
                    >
                        Reset Filters
                    </Button>

                    <Button
                        variant='outlined'
                        sx={{ boxShadow: "none" }}
                        onClick={() => handleClose()}
                    >
                        Close
                    </Button>

                </Box>

            </Box>
        </Menu>
    )
}

export default FilterMenu