import React from 'react';
import Fade from '@mui/material/Fade';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Divider, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});
const PreviewComponent = ({ open, setOpen, order }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    const handleClose = () => {
        setOpen(false);
    };

    if (!order || !order.fromAddress || !order.toAddress || !order.items) {
        return (
            <Dialog open={open} onClose={handleClose}>
                <Box sx={{ p: 4, textAlign: 'center' }}>
                    <Typography variant="h6">Loading...</Typography>
                </Box>
            </Dialog>
        );
    }
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('en-IN').format(value);
    };
    
    return (
        <Dialog
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
            sx={{
                "& .MuiDialog-container": {
                    alignItems: "center",
                    justifyContent: 'center'
                },
                "& .MuiPaper-root": {
                    margin: '20px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.04)',
                    minWidth: '1200px',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    overflowY: 'auto',
                }
            }}
            hideBackdrop={false}
        >
            <Box sx={{
                width: '100%',
                maxWidth: '1250px',
                margin: '0 auto',
                padding: '40px',
                border: '1px solid #ccc',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <IconButton
                    id="modal-close-icon"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 15,
                        top: 10
                    }}>
                    <CloseIcon />
                </IconButton>

                <Typography variant="h5" align="center" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>Order Preview</Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', width: '100%' }}>
                    <Box sx={{ width: '30%' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>From</Typography>
                        <Divider sx={{ marginBottom: '10px' }} />
                        <Typography>Full Name: {order.fromAddress.fullName}</Typography>
                        <Typography>Email: {order.fromAddress.email}</Typography>
                        <Typography>Contact Number: {order.fromAddress.contactNumber}</Typography>
                        <Typography>Address: {order.fromAddress.address}</Typography>
                        <Typography>City: {order.fromAddress.city}</Typography>
                        <Typography>State: {order.fromAddress.state}</Typography>
                        <Typography>Zip Code: {order.fromAddress.zipCode}</Typography>
                        <Typography>Country: {order.fromAddress.country}</Typography>
                    </Box>

                    <Box sx={{ width: '30%' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>To</Typography>
                        <Divider sx={{ marginBottom: '10px' }} />
                        <Typography>Full Name: {order.toAddress.fullName}</Typography>
                        <Typography>Email: {order.toAddress.email}</Typography>
                        <Typography>Contact Number: {order.toAddress.contactNumber}</Typography>
                        <Typography>Address: {order.toAddress.address}</Typography>
                        <Typography>City: {order.toAddress.city}</Typography>
                        <Typography>State: {order.toAddress.state}</Typography>
                        <Typography>Zip Code: {order.toAddress.zipCode}</Typography>
                        <Typography>Country: {order.toAddress.country}</Typography>
                    </Box>
                </Box>

                <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight: 'bold', width: '100%' }}>Items</Typography>
                <TableContainer component={Paper} sx={{ border: '1px solid #ddd', width: '100%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Price</TableCell>
                                <TableCell align="center">Tax(%)</TableCell>
                                <TableCell align="center">Tax Amount(₹)</TableCell>
                                <TableCell align="center">Discount(%)</TableCell>
                                <TableCell align="center">Discount Amount(₹)</TableCell>
                                <TableCell align="center">Amount(₹)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.product}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell align="center">{item.quantity}</TableCell>
                                    <TableCell align="center">{item.rate}</TableCell>
                                    <TableCell align="center">{item.taxRate}</TableCell>
                                    <TableCell align="center">{item.taxAmount}</TableCell>
                                    <TableCell align="center">{item.discount}</TableCell>
                                    <TableCell align="center">{item.discountAmount}</TableCell>
                                    <TableCell align="center">{item.amount?.toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{ marginTop: '8px', width: '100%' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Summary</Typography>
                    <Divider sx={{ marginBottom: '10px' }} />
                    {[
                        { label: 'SubTotal', value: order.totalSubTotal },
                        { label: 'Tax', value: order.totalTax },
                        { label: 'Discount', value: order.totalDiscount }
                    ].map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <Typography sx={{ textAlign: 'right', width: '80%', fontWeight: 'normal' }}>{item.label} :</Typography>
                            <Typography sx={{ textAlign: 'right', width: '20%' }}>{item.value?.toFixed(2)}</Typography>
                        </Box>
                    ))}

                    <Divider sx={{ marginBottom: '10px', width: '27%', marginLeft:'auto' }} />

                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Typography sx={{ textAlign: 'right', width: '80%', fontWeight: 'bold' }}>Total : </Typography>
                        <Typography sx={{ textAlign: 'right', width: '20%' }}>₹ {order.netPayable?.toFixed(2)}</Typography>
                    </Box>
                </Box>


                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pt: '10px',
                    pb: '20px',
                    gap: "15px"
                }}>
                    <LoadingButton
                        variant='outlined'
                        onClick={handleClose}
                    >
                        Cancel
                    </LoadingButton>
                    <LoadingButton
                        variant='outlined'
                        color='primary'
                    >
                        Print
                    </LoadingButton>
                </Box>
            </Box>
        </Dialog>
    );
};
export default PreviewComponent;
