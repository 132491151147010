/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import propTypes from 'prop-types'
import CachedIcon from '@mui/icons-material/Cached';
import commonMessage from '../../../utils/commonMessage.json'

//mui material
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip, Box, IconButton, Tooltip, Typography, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

//components

import { CustomSkeleton } from '../../skeleton/skeletonLoader';
import NetworkError from '../../../components/Error/networkError';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  color: theme.palette.grey[700],
  '&:hover': {
    color: theme.palette.grey[200],
  }
}));

// Show table filtes.
const TableFilterSection = styled('div')(() => ({
  width: 'auto',
  padding: '16px 16px 16px 16px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));

export const PaginationTable = ({
  page = 0,
  tableFilters,
  ActionJsx,
  ActionModals,
  // setPage,
  rowsPerPage = 10,
  // setRowsPerPage,
  // setLoad,
  // count = 0,
  // error = false,
  loading,
  // setLoading,
  rows,
  columns,
  // setPaginationLoad,
  maxHeight = 680,
  mapKey
}) => {

  // const handleChangePage = (e, newPage) => {
  //   setPage(newPage)
  //   setLoad(true)
  //   setLoading(true)
  //   setPaginationLoad(true)
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  //   setLoad(true)
  //   setLoading(true)
  //   setPaginationLoad(true)
  // };

  const chipColors = {
    1000: { color: '#50AB64', backgroundColor: 'rgba(40, 167, 69, 0.20)', },
    A: { color: '#50AB64', backgroundColor: 'rgba(40, 167, 69, 0.20)', },
    C: { color: '#50AB64', backgroundColor: 'rgba(40, 167, 69, 0.20)', },

    104: { color: '#E2533F', backgroundColor: 'rgba(236, 64, 64, 0.20)', },
    NA: { color: '#E2533F', backgroundColor: 'rgba(236, 64, 64, 0.20)', },
    101: { color: '#50AB64', backgroundColor: 'rgba(40, 167, 69, 0.20)', },
    CG: { color: '#50AB64', backgroundColor: 'rgba(40, 167, 69, 0.20)', },
    100: { color: '#D19244', backgroundColor: 'rgba(209, 146, 68, 0.20)', },
    p: { color: '#D19244', backgroundColor: 'rgba(209, 146, 68, 0.20)', },
    102: { color: '#E2533F', backgroundColor: 'rgba(236, 64, 64, 0.20)', },
    O: { color: '#E2533F', backgroundColor: 'rgba(236, 64, 64, 0.20)', },
  }

  const statusMapper = (value) => {
    const map = {
      100: "Pending",
      101: "Running",
      102: "Pause",
      104: "Stopped",
      1000: "Complete",
      'p': 'Pending',
      'A': 'Answered',
      'NA': 'Not Answered',
      'CG': 'Congestion',
      'O': 'Other',
      'C': "Calling"
    }
    if (map[value]) return map[value]
    return value;
  }

  const createStatusElement = (value) => {

    const statusChip = (value) => (
      <Chip label={statusMapper(value)} sx={{
        p: '2px 0px 2px 0px',
        height: 'auto',
        borderRadius: '5px',
        fontWeight: 500,
        "&:hover": {
          ...chipColors?.[value]
        },
        ...chipColors?.[value]
      }} />
    )
    const allStatus = {
      100: statusChip,
      101: statusChip,
      102: statusChip,
      104: statusChip,
      1000: statusChip,
      p: statusChip,
      A: statusChip,
      NA: statusChip,
      CG: statusChip,
      O: statusChip,
      C: statusChip
    }
    return allStatus?.[value](value)
  }

  const statusJsx = (id, align, value) => {

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.400',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.700',
        }}>
        {createStatusElement(value)}
      </TableCell>
    )
  }
  const reportStatusJsx = (id, align, value, row) => {

    let updatedValue = value;
    if (row?.status === 1 && value === "p") updatedValue = "C";

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.400',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.700',
        }}>
        {createStatusElement(updatedValue)}
      </TableCell>
    )
  }

  const showPercentJsx = (id, align, value, userId, totalCount, approvedCount) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        color: 'grey.700',
        borderBottom: '0.5px dashed',
        borderColor: 'grey.700',
        p: '25px'
      }}>
      {(approvedCount / totalCount) * 100}%
    </TableCell>
  )

  const dateFormatJsx = (id, align, value) => {
    const date = moment(value * 1000);

    const formattedDate = date.format('D MMM, YYYY');
    const formattedTime = date.format('h:mm:ss A');

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          borderBottom: '0.5px dashed',
          borderColor: 'grey.700',
          p: '0px'
        }}>
        <>
          <Box>{value !== undefined ? formattedDate : "--"}</Box>
          <Box sx={{
            color: 'grey.600',
          }}>{value && formattedTime}</Box>
        </>
      </TableCell>)
  }

  const roleFormatJsx = (id, align, value) => {
    const roleFormatMapper = {
      superAdmin: "Super-Admin",
      admin: "Admin",
      agent: "Agent"
    }

    return (
      <TableCell
        key={id}
        align={align}
        sx={{
          color: 'grey.700',
          borderBottom: '0.5px dashed',
          borderColor: 'grey.400',
          p: '0px'
        }}>
        {roleFormatMapper[value]}
      </TableCell>
    )

  }

  const defaultRowJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{
        borderBottom: '0.5px dashed',
        borderColor: 'grey.700',
        p: '0px'
      }}>
      {value !== undefined ? value : "--"}
    </TableCell>
  )


  const contactTypeMapper = {
    contact_csv: "Contact Csv",
    contact_numbers: "Contact Numbers"
  }

  const contactTypeJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{

        borderBottom: '0.5px dashed',
        borderColor: 'grey.700',
        p: '0px'

      }}>
      {contactTypeMapper[value]}
    </TableCell>
  )

  const sequenceJsx = (id, align, ...other) => {

    const index = other[2];
    const seqNo = (page * rowsPerPage) + index + 1;

    return <TableCell
      key={id}
      align={align}
      sx={{

        borderBottom: '0.5px dashed',
        borderColor: 'grey.700',
        p: '0px'

      }}>
      {seqNo}
    </TableCell>
  }

  const campaignJsx = (id, align, value, row) => {
    const { campaign_reschedule, total_reschedule, current_reschedule_id } = row;

    const Content = () => {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography variant='body1'>Rescheduled info</Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-around" }}>
              <Typography variant='body2' sx={{ width: "90%" }}>No of Reschedule:</Typography>
              <Typography variant='body2'>{total_reschedule}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-around" }}>
              <Typography variant='body2' sx={{ width: "90%" }}>Current  Reschedule:</Typography>
              <Typography variant='body2'>{current_reschedule_id}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-around" }}>
              <Typography variant='body2' sx={{ width: "90%" }}>Remaining  Reschedule:</Typography>
              <Typography variant='body2'>{total_reschedule - current_reschedule_id}</Typography>
            </Box>
          </Box>
        </Box>
      )
    }

    return <TableCell
      key={id}
      align={align}
      sx={{

        borderBottom: '0.5px dashed',
        borderColor: 'grey.700',
        p: '0px'

      }}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0px" }}>

        {campaign_reschedule === "Y" && <Tooltip title={<Content />} enterTouchDelay={0} componentsProps={{
          tooltip: {
            sx: {
              color: "text.primary",
              backgroundColor: "background.paper",
              fontSize: "2em"
            }
          }
        }} >
          <IconButton>
            <CachedIcon color='primary' />
          </IconButton>
        </Tooltip>}
        {value}
      </Box>
    </TableCell>
  }

  const campaignTypeJsx = (id, align, value) => (
    <TableCell
      key={id}
      align={align}
      sx={{

        borderBottom: '0.5px dashed',
        borderColor: 'grey.700',
        p: '0px'

      }}>
      {value.toUpperCase()}
    </TableCell>
  )


  function LinearDeterminate(props) {


    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    );
  }

  const progressJsx = (id, align, value) => {

    const { readingPercentage, sendingPercentage, reportingPercentage } = value;

    return (
      <TableCell
        key={id}
        align={align}
        sx={{

          borderBottom: '0.5px dashed',
          borderColor: 'grey.700',
          p: '0px'

        }}>

        <Table sx={{ minWidth: 200, borderRadius: '6px' }} aria-label="table" size="small">
          <TableBody>

            <TableRow
              // key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center" style={{ padding: 0 }} >

                <Typography >
                  {`Reading`}
                </Typography>
                <LinearDeterminate value={readingPercentage * 100} />
              </TableCell>

              <TableCell align='center' style={{ padding: 5 }}>

                <Typography>
                  {`>`}
                </Typography>
              </TableCell>
              <TableCell align="center" style={{ padding: 0 }}>

                <Typography >
                  {`Sending`}
                </Typography>
                <LinearDeterminate value={sendingPercentage * 100} />
              </TableCell>
              <TableCell align='center' style={{ padding: 5 }}>

                <Typography>
                  {`>`}
                </Typography>
              </TableCell>
              <TableCell align="center" style={{ padding: 0 }}>

                <Typography >
                  {`Reporting`}
                </Typography>
                <LinearDeterminate value={reportingPercentage * 100} />
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableCell>
    )
  }

  

  const actionJsx = (id, align, value, row) => {
    return <ActionJsx key={id} id={id} align={align} value={value} row={row} />
  }

  const tableRowData = {
    // kycStatus: statusJsx,
    actions: actionJsx,
    documentApprovedStatus: showPercentJsx,
    create_date: dateFormatJsx,
    update_date: dateFormatJsx,
    start_date: dateFormatJsx,
    call_start: dateFormatJsx,
    call_connect: dateFormatJsx,
    call_end: dateFormatJsx,
    Validity: dateFormatJsx,
    role: roleFormatJsx,
    status: statusJsx,
    user_report: reportStatusJsx,
    contact_type: contactTypeJsx,
    progress: progressJsx,
    campaign_type: campaignTypeJsx,
    seq_no: sequenceJsx,
    cmp_id: campaignJsx
  }

  return (
    // <Paper sx={{
    //   overflow: 'hidden',
    //   // borderRadius: '0px',
    //   width: "100%"
    // }}>

    <Box>


      <TableFilterSection>
        {tableFilters}
      </TableFilterSection>

      <TableContainer sx={{ maxHeight, width: "100%", borderRadius: '6px', border: '0.5px dashed', borderColor: 'grey.500' }}>
        <Table stickyHeader aria-label="sticky table" size="small">

          <TableHead size="small" sx={{
            // position: "sticky"
            backgroundColor: 'grey.300',
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderColor: 'grey.400'
          }} >
            <TableRow >
              {columns.map(({ id, align, minWidth, label }) => (
                <CustomTableCell
                  key={id}
                  align={align}
                  style={{ minWidth: minWidth, }}
                  sx={{
                    // backgroundColor: 'grey.200',
                    // color: 'grey.600',
                    padding: "6px 0px"
                  }}
                >
                  {label}
                </CustomTableCell>
              ))}
            </TableRow>
          </TableHead>

          {!loading ?
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row[mapKey] || row?.userId || row?.cmp_id}
                >
                  {columns.map(({ id, align }) => (tableRowData?.[id] || defaultRowJsx)(id, align, row[id], row, index))}
                </TableRow>
              ))}
            </TableBody>
            :
            <TableBody>
              {[1, 2].map((item) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={item}
                  >
                    {columns.map(({ id, align }) => (
                      <TableCell
                        key={id}
                        align={align}
                        sx={{
                          // color: 'grey.400',
                          borderBottom: '1px solid',
                          borderColor: 'grey.700'
                          // borderColor: 'grey.800'
                        }}
                      >
                        <CustomSkeleton variant={"text"} component={"h6"} width={"100%"} />
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}


            </TableBody>
          }
        </Table>
        {!loading && rows.length === 0 &&
          < Box sx={{
            height: {
              xs: "100px",
              sm: "480px",
              md: "480px",
              lg: "480px",
              xl: "100px",
            },
            display: 'flex',
            justifyContent: 'center'
          }}>
            <NetworkError message={commonMessage?.noDataFound} />
          </Box>
        }
      </TableContainer>

      {/* {count > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            // backgroundColor: 'grey.200',
            // color: 'grey.700',
            // height:"70px"
          }}
        />
      )} */}


      {ActionModals && <ActionModals />}
    </Box>
    // </Paper>
  )
}

PaginationTable.propTypes = {
  page: propTypes.number,
  setPage: propTypes.func.isRequired,
  rowsPerPage: propTypes.number,
  setRowsPerPage: propTypes.func.isRequired,
  setLoad: propTypes.func.isRequired,
  count: propTypes.number,
  error: propTypes.bool,
  loading: propTypes.bool,
  setLoading: propTypes.func,
  rows: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  maxHeight: propTypes.number,
}
