
import React from 'react';
import { Helmet } from 'react-helmet-async';
import ErrorMessage from "../utils/ErrorMessage.json"

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Box, Button } from '@mui/material';

// Icons
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import pageTitle from "../utils/pageTitles.json";
import { BlankCard } from '../components/cards/blankCard';
import { useNavigate } from 'react-router-dom';
import { AdminUserListTable } from '../sections/users/adminUserListTable';
import { renderErrorMessage } from '../lib/toastMessage';
import UsersListSvg from '../assets/svgs/usersList';
import { checkButtonAccess } from '../utils/common';

// home main section container.
const MainSection = styled('div')(({ theme }) => ({
    padding: "64px 20px 0px 88px",
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: '100vh',
    overflow: 'hidden'
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
}));

const DetailsListSection = styled('div')(() => ({
    width: '100%',
    height: 'calc(100vh - 155px)',//'554px',
    overflow: 'hidden',
    overflowY: "scroll",
}));

const adminUserList = (
    <Box sx={{ width: '100%' }}>
        <DetailsListSection className='reportListScroll'>
            <AdminUserListTable />
        </DetailsListSection>
    </Box>
)

const Users = () => {
    const navigate = useNavigate()
    const handleClick = () => {
        if (!checkButtonAccess("addNewUser", "w")) return renderErrorMessage(ErrorMessage["invalidClick"])
        return navigate("/add_new_user", { state: { edit: false } })
    }
    return (
        <>
            <Helmet>
                <title> {`${pageTitle?.admin}`} </title>
            </Helmet>

            <MainSection>

                {/* Heading and filters */}
                <HeadingSection>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Box sx={{
                            widht: '16px',
                            height: '16px',
                            mt: -1.8
                        }}>
                            <UsersListSvg />
                        </Box>
                        <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                            Admins
                        </Typography>
                    </Box>

                    {checkButtonAccess("addNewUser", "r") && <Button
                        startIcon={<PersonAddIcon />}
                        sx={{
                            backgroundColor: 'rgb(33, 43, 54)',
                            color: 'grey.100',
                            p: '0px 20px 0px 20px',
                            '&:hover': {
                                backgroundColor: 'rgb(69, 79, 91)'
                            }
                        }}
                        onClick={() => handleClick()}
                    >
                        New Admin
                    </Button>}

                </HeadingSection>

                <BlankCard
                    key="1"
                    sx={{
                        height: '100%',
                        width: '100%',
                        padding: '0px',
                        overflow: 'hidden',
                        // borderTopLeftRadius: '0px',
                        // borderTopRightRadius: '0px'
                    }}
                >
                    {adminUserList}
                </BlankCard>

            </MainSection>

        </>
    )
}

export default Users;
