import React from 'react';

import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import BrandingTable from '../sections/branding/Branding';
import AdSvg from '../assets/svgs/adSvg';
import { Helmet } from 'react-helmet-async';
import pageTitle from "../utils/pageTitles.json";

const MainSection = styled('div')(() => ({
    width: '100%',
    padding: '64px 20px 0px 88px',
    backgroundColor: "rgb(240, 240, 240)"/* theme.palette.background.default */,
    height: '100vh'
}));

const MainSectionInner = styled('div')(({ theme }) => ({
    backgroundColor: "#fff",
    padding: '30px 20px',
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: `calc(${window.innerHeight}px - 157px)`
}));

// Show home hading and filtes
const HeadingSection = styled('div')(() => ({
    width: '100%',
    padding: "20px 0px 20px 0px",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
}));

const Branding = () => {
    return (
        <>
         <Helmet>
                <title> {`${pageTitle?.branding}`} </title>
            </Helmet>
        <MainSection>
            <HeadingSection>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Box sx={{
                        widht: '16px',
                        height: '16px',
                        mt: -1.8
                    }}>
                        <AdSvg />
                    </Box>
                    <Typography variant='h5' sx={{ color: 'grey.10', fontWeight: 600 }}>
                        Branding
                    </Typography>
                </Box>

            </HeadingSection>

            <MainSectionInner>
                <BrandingTable />
            </MainSectionInner>
        </MainSection>
        </>
    )
}

export default Branding